/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  background-color: #fff;
  color: #000;
  font-size: 12px
}

body,
ul,
ol,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
form,
fieldset,
legend,
input,
textarea,
button,
p,
blockquote,
th,
td,
pre,
xmp {
  margin: 0;
  padding: 0
}

body,
input,
p,
textarea,
button,
select,
pre,
xmp,
tt,
code,
kbd,
samp {
  line-height: 1.5;
  font-family: Source Sans Pro, Arial, sans-serif
}

h1,
h2,
h3,
h4,
h5,
h6,
small,
big,
input,
textarea,
button,
select {
  font-size: 100%
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: tahoma, arial, "Hiragino Sans GB", "微软雅黑", simsun, sans-serif
  font-family: 'Cormorant Garamond', serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
  font-weight: normal
}

address,
cite,
dfn,
em,
i,
optgroup,
var {
  font-style: normal
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left
}

caption,
th {
  text-align: inherit
}

ul,
ol,
menu {
  list-style: none
}

fieldset,
img {
  border: 0
}

img,
object,
input,
textarea,
button,
select {
  vertical-align: middle
}

article,
aside,
footer,
header,
section,
nav,
figure,
figcaption,
hgroup,
details,
menu {
  display: block
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "\0020"
}

textarea {
  overflow: auto;
  resize: vertical
}

input,
textarea,
button,
select,
a {
  outline: 0 none;
  border: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0
}

mark {
  background-color: transparent
}

a,
ins,
s,
u,
del {
  text-decoration: none
}

sup,
sub {
  vertical-align: baseline
}

html {
  overflow-x: hidden;
  height: 100%;
  font-size: 50px;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
}

body {
  font-family: Arial, "Microsoft Yahei", "Helvetica Neue", Helvetica, sans-serif;
  color: #333;
  font-size: .28em;
  line-height: 1;
  -webkit-text-size-adjust: none;
}

hr {
  height: .02rem;
  margin: .1rem 0;
  border: medium none;
  border-top: .02rem solid #cacaca;
}

a {
  color: #25a4bb;
  text-decoration: none;
}

body {
  font-family: Source Sans Pro, Arial, sans-serif;
  font-size: 12px;
  color: $tc1;
}


html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.w100 {
  width: 100%;
}

.w80 {
  width: 80%;
}

ul,
li {
  list-style: none;
}

img {
  border: none;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

textarea {
  overflow: auto;
  border: 1px solid #eee;
  resize: none;
  padding: 5px;
}

/*-----------------------------------------------------------------常用的公共样式--开始--------------------------------------------------------------------------*/
/*左右浮动*/
.float-r {
  float: right;
}

.float-l {
  float: left;
}

.float-none {
  float: none;
}

/*左右对齐*/
.text-r {
  text-align: right;
}

.text-l {
  text-align: left;
}

.text-c {
  text-align: center;
}


/*相对定位*/
.pos-relative {
  position: relative;
}

/*变块元素*/
.dis-block {
  display: block;
}

/*变行内块元素元素*/
.dis-in-block {
  display: inline-block;
}

/*变行行内元素*/
.dis-inline {
  display: inline;
}

/*清浮动*/
.clear-fix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

/*文字不换行,显示省略号*/
.overflow-els {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*文字换行,显示两行，超出隐藏*/
.overflow-els-ver {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.w65 {
  width: 60px;
}

/*常用外边距*/
.margin-t5 {
  margin-top: 5px;
}

.margin-t10 {
  margin-top: 10px;
}

.margin-t15 {
  margin-top: 15px;
}

.margin-t20 {
  margin-top: 20px;
}

.margin-b5 {
  margin-bottom: 5px;
}

.margin-l15 {
  margin-left: 15px;
}

.margin-b10 {
  margin-bottom: 10px;
}

.margin-b15 {
  margin-bottom: 15px;
}

.margin-lr10 {
  margin: 0 10px;
}

.margin-lr5 {
  margin: 0 5px;
}

.margin-l10 {
  margin-left: 10px;
}

.margin-r10 {
  margin-right: 10px;
}

/*常用内边距*/
.padding-l10px {
  padding-left: 10px;
}

.padding-l20 {
  padding-left: 20px;
}

.padding-r10px {
  padding-right: 10px;
}

.padding-r15px {
  padding-right: 15px;
}

.padding-lr8px {
  padding: 0 8px;
}

.pad-l15px {
  padding-left: 15px;
}

.pad-l25px {
  padding-left: 25px !important;
}

.pad-l46px {
  padding-left: 46px;
}

.pad-b15px {
  padding-bottom: 15px;
}

.pad-5px {
  padding: 5px;
}

.pad-10px {
  padding: 10px;
}

.pad-t15px {
  padding-top: 15px;
}

.tr-empty {
  color: #888;
  font-size: 14px;
}

/*设置滚动条*/
// ::-webkit-scrollbar-track {
//   background-color: #F5F5F5;
// }
// ::-webkit-scrollbar-thumb {
//   background-color: #888;
//   border-radius: 1px;
// }
// ::-webkit-scrollbar {
//   width: 6px;
//   height: 8px!important;
//   background-color: #F5F5F5;
// }

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/* flex样式 */
.display-flex {
  display: flex;
}

.display-flex-center {
  display: flex;
  align-items: center;
}

.flex-flow-row {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
}

.flex-flow-column {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: column;
}

.flex-1 {
  flex: 1;
}

/*设置全局下划线*/
.text-decoration-cursor {
  text-decoration: underline;
  cursor: pointer;
}

.bottom-divider {
  border-bottom: 1px solid $dc1;
}

/* 去除i em i 默认样式 */
.i-nostyle,
.em-nostyle {
  font-style: normal;
}

.required {
  color: $tc4;
}

//审批意见
.approvalText {
  text-align: left;
  position: relative;
  top: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bg-outline {
  outline: #0796f9 solid 1px;
}

.red {
  color: red;
}

.el-notification {
  white-space: pre-wrap !important;
}

/*左右对齐*/
.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}