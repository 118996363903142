<template>
  <div id="app"
       style="height: 100%">
    <router-view></router-view>
  </div>
</template>

<style lang="stylus">
*
  padding 0
  margin 0
*
  -webkit-touch-callout none /* 系统默认菜单被禁用 */
  -webkit-user-select none /* webkit浏览器 */
  -khtml-user-select none /* 早期浏览器 */
  -moz-user-select none /* 火狐 */
  -ms-user-select none /* IE10 */
  user-select none
input, textarea
  -webkit-user-select auto /* webkit浏览器 */
  margin 0px
  padding 0px
  outline none
*
  -webkit-tap-highlight-color rgba(0, 0, 0, 0)
  -webkit-tap-highlight-color transparent /* For some Androids */
.common-load
  position fixed
  width 100%
  height 100%
  display flex
  justify-content center
  align-items center
  background-color #66666633
  z-index 999999
.common-page
  display flex
  flex-direction column
.common-black
  display flex
  flex-direction column
  margin-bottom 0.6rem
  background-color #fff
/* 页面块标题 */
.common-div-title
  height 0.85rem
  background-color $divBgColor
  padding-left 0.2rem
  color #fff
  line-height 0.85rem
  font-size $divTitleSize
  display flex
/* 公共横条样式 */
.common-div-item
  width 100%
  min-height 1rem
  display flex
  flex-direction row
  align-items center
.common-item-icon
  width 0.48rem
  height 0.48rem
  line-height 0.48rem
  text-align center
  margin-right 0.15rem
.common-item-title
  font-size $textSize
  color $textColor
  padding-left 0.2rem
.common-item-content
  flex 1
  display flex
  align-items center
  justify-content flex-end
  margin 0 0.3rem
  font-size $textSize
  color $textContentColor
  line-height 0.5rem
.common-item-input
  font-size $textSize
  // margin 0.05rem
  // padding 0 0.1rem
  color $textColor
  height 0.8rem
  line-height 0.8rem
  flex 1
  margin-right 0.15rem
.common-item-right
  width 0.5rem
  height 0.5rem
  margin-right 0.2rem
  line-height 0.5rem
  text-align center
  color #bebebe
.common-div-submit
  display flex
  flex-direction row
  .common-btn-submit
    margin 0.48rem 0.24rem
</style>
