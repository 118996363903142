// 文字
$tc1: #333333;
$tc2: #666666;
$tc3: #999999;
// $tc4: #fb1c1c;
$tc4: #fb1c1c;
$tc5: #0066CC;
$tc6: #535353;
$d9: #D9D9D9;

// 背景
$bc1: #FFFFFF;
$bc2: #F5F5F5;
$bc3: #1e91d2;
// $bc4: #fb1c1c;
$bc4: #0796F9;
$bc5: #EBEBEB;
// $bc6: #004C96;
$bc6: #0796F9;


$active-color: #0796F9;

// 分割线
$dc1: #e5e5e5;


$success: #46C37B !default;
$warning: #E6A43A !default;
$danger: #ea3c40 !default;
$info: #909399 !default;
;


//红色

$active-red-color: #ED1111;
// 图标颜色
$ico: #959599;