
          @import "assets/styles/variable.scss";
        

* {
  padding: 0;
  margin: 0;
}

* {
  -webkit-touch-callout: none; /* 系统默认菜单被禁用 */
  -webkit-user-select: none; /* webkit浏览器 */
  -khtml-user-select: none; /* 早期浏览器 */
  -moz-user-select: none; /* 火狐 */
  -ms-user-select: none; /* IE10 */
  user-select: none;
}
input,
textarea {
  /* webkit浏览器 */
  margin: 0px;
  padding: 0px;
  outline: none;
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */
}

// 更改鼠标划过背景色字体色及borer显示
.el-menu-item:not(.is-disabled):hover {
  // background: #d81e0633 !important;
  // color: #fff !important;
}

body {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.el-menu-main {
  display: flex;
  justify-content: center;
  // border-bottom: none !important;
}

.main-header {
  height: 60px;
  width: 100%;
  background-color: #fff;
  display: none; /* 隐藏菜单默认状态 */
  border-bottom: solid 1px #e6e6e6;
}

.main-header-open {
  position: absolute;
  height: 60px;
  width: 80px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-header-open-img {
  height: 24px;
  width: 24px;
}

.el-menu-main-vertical {
  flex-direction: column;
  position: absolute;
  background-color: #333;
  width: 100% !important;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: none; /* 隐藏菜单默认状态 */
}

.main-header-logo {
  width: 155px;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .main-header-logo-img {
    width: 155px;
    height: 50px;
  }
}

.logo {
  position: absolute;
  top: 0;
  left: 15px;
  width: 155px;
  height: 60px;
  float: left;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo-img {
    width: 155px;
    height: 50px;
  }
}

.item-home {
  margin-left: 200px !important;
}

.el-menu-item {
  border-bottom: none !important;
}

.el-menu-item {
  border-bottom: none !important;
}

/* 响应式设置 */
@media (max-width: 900px) {
  .el-menu-main {
    flex-direction: column;
    position: absolute;
    background-color: #333;
    width: 100%;
    left: 0;
    top: 0;
    display: none; /* 隐藏菜单默认状态 */
  }

  .main-header {
    display: flex;
    justify-content: center;
  }

  .el-menu-main-vertical {
    display: flex;
    border-bottom: none !important;
    width: 100% !important;
    height: 100%;
  }

  .menu-button {
    display: inline-block;
  }

  .menu[style*="display: block"] {
    display: block; /* 显示菜单 */
  }
}
