.el-pagination {
  font-weight: normal;
}

.item-left {
  overflow: auto;
  height: calc(100vh - 255px);
}

.el-tree-node.is-expanded>.el-tree-node__children {
  overflow: visible;
}

.blues {
  color: #409EFF;
}

.oranges {
  color: #E6A23C;
}

.success {
  color: #67C23A;
}

.danger {
  color: #F56C6C;
}

.h100 {
  height: 100%;
}

// .el-input-group--append .el-input__inner {
//   border-radius: 3px 0 0 3px;
// }
// .el-input--mini .el-input__inner{
//   line-height: normal !important;
//   border-radius: 3px;
// }
.el-button {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 6px;
}

.el-input__inner {
  height: 28px;
  line-height: normal !important;
  border-radius: 3px;
}

.el-image-viewer__wrapper .el-image-viewer__canvas .el-image-viewer__img {
  max-height: 100% !important;
  max-width: 100% !important;
}

.el-tooltip__popper {
  max-width: 400px;
  line-height: 180%;
}

.tr-title-comments {
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 650px);
}

.text-hidden {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 35px;
  width: 100%;
}

// el-tree 文件夹图标
// .el-tree .el-tree-node__expand-icon.expanded {
//   -webkit-transform: rotate(0deg);
//   transform: rotate(0deg);
// }
// .el-tree .el-icon-caret-right:before {
//   content: "\E78A";
// }
// .el-tree .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
//   content: "\E784";
// }
// ------------------------------
//.el-tabs {
//  &__header {
//    border: none;
//  }
//  &--card>.el-tabs__header {
//    border-bottom: 1px solid $bc3;
//
//    .el-tabs__nav {
//      border: none;
//    }
//  }
//  &__item {
//    color: $bc3;
//    font-size: 14px;
//    border: none !important;
//
//    &.is-active {
//      background: $bc3;
//      color: white;
//    }
//  }
//}
.cblue {
  color: #4ba7db;
  cursor: pointer;
}

.el-popover {
  // background: #E5E5E5;
  background: #fff;
  padding: 5px;
  margin: 0 0 0 5px;
  // border: 1px solid #4275B7;
}

// 弹窗卡片样式
.el-dialogs>.el-dialog {
  border-radius: 0 !important;
  border: 1px solid #4275B7;
  padding: 30px 20px;

  >.el-dialog__header {
    height: 35px !important;
    background: #fff !important;
    border-radius: 0 !important;

    >.el-dialog__headerbtn {
      top: 15px !important;
      right: 10px !important;
      display: none;

      >.el-dialog__close {
        background: none;
      }
    }
  }

  >.el-dialog__body {
    padding: 0 !important;
    ;
    border: none !important;
    margin-top: 0px !important;
  }

  >.el-dialog__footer {
    border: none !important;
    border-radius: 0 !important;
  }

  >.dialog-header {
    .outline {
      position: absolute;
      top: 7px;
      right: 45px;
      cursor: pointer;
      font-size: 19px;
      color: #aaa;
    }

    .outlines {
      position: absolute;
      top: 7px;
      right: 12px;
      cursor: pointer;
      font-size: 19px;
      color: #aaa;
    }

    .title {
      position: absolute;
      top: 7px;
      left: 20px;
      font-size: 14px;
    }
  }

  .outline {
    position: absolute;
    top: 7px;
    right: 45px;
    cursor: pointer;
    font-size: 19px;
    color: #aaa;
  }

  .outlines {
    position: absolute;
    top: 7px;
    right: 12px;
    cursor: pointer;
    font-size: 19px;
    color: #aaa;
  }

  .lefticon {
    position: absolute;
    top: 7px;
    left: 10px;
    display: inline-block;
    font-size: 15px;
  }

  .lefticons {
    float: left;
    margin: 0px 5px;
    color: #09aee6;
    font-weight: 600;
    font-size: 19px;
  }

  .newicons {
    color: #333;
    font-size: 20px;
    position: relative;
    top: 2px;
    padding: 0 8px 0 0;
  }

  .title {
    position: absolute;
    top: 7px;
    left: 20px;
    font-size: 14px;
  }
}

.el-padding>.el-dialog {
  padding: 0 !important;
}

.tabs_cont {
  position: fixed;
  z-index: 9;
  bottom: 0;
  margin: 0;
  width: 96%;
  border: 1px solid #EBEEF5;
  border-bottom: none;
  border-left: none;
  background: #fff;

  >.el-tabs>.el-tabs__header {
    margin: 0;

    // z-index: 9999;
    >.el-tabs__nav-wrap>.el-tabs__nav-scroll {
      background: #fff;

      >.el-tabs__nav>.el-tabs__item {
        height: 30px;
        line-height: 30px;
      }

      >.el-tabs__nav>.el-tabs__item.is-active {
        color: #409EFF !important;
      }
    }

    >.el-tabs__nav-wrap>.el-tabs__nav-prev {
      line-height: 35px !important;
    }

    >.el-tabs__nav-wrap>.el-tabs__nav-next {
      line-height: 35px !important;
    }
  }

  .el-tabs__content>.el-tab-pane>.el-dialog__wrapper {
    bottom: 32px;
  }

  .tabsicos {
    float: right;
    font-size: 18px;
    font-weight: 600;
    color: #666;
    line-height: 30px;
    margin: 0 10px;
    cursor: pointer;
  }

  .unfolds {
    float: right;
    font-size: 18px;
    font-weight: 600;
    color: #666;
    line-height: 30px;
    margin: 0 10px;
    cursor: pointer;
  }

  .el-card {
    border: 1px solid #dedede !important;
    box-shadow: none;
  }
}

.tabs_fold {
  width: 6%;
  right: 0;
  border-left: 1px solid #EBEEF5;

  >.el-tabs {
    width: 0 !important;
  }

  >.el-tabs>.el-tabs__header {
    >.el-tabs__nav-wrap {
      padding: 0;
    }

    >.el-tabs__nav-wrap>.el-tabs__nav-prev {
      display: none;
    }

    >.el-tabs__nav-wrap>.el-tabs__nav-next {
      display: none;
    }
  }
}

.v-modal {
  opacity: 0.2;
}

.el-form-item__label {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
  display: inline-block;
}

.el-input.is-disabled .el-input__inner,
.el-textarea.is-disabled .el-textarea__inner {
  color: $tc1  !important;
}

.el-table {
  font-size: 12px !important;

  .cell {
    padding-left: 7px !important;
    padding-right: 7px;

  }

  th div {
    padding-left: 2px;
    padding-right: 2px;
    text-overflow: unset !important;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  // ::-webkit-scrollbar {
  //   // width: 6px;
  //   // height: 15px;
  //   // background-color: #F5F5F5;
  //   width: 7px;
  //   height: 7px;
  //   -webkit-border-radius: 5px;
  //   background-color: rgba(0,0,0,0.05);
  // }

  th.gutter {
    display: table-cell !important;
  }
}

.el-upload {
  &--picture-card {
    border-radius: 1px;
  }

  &-list__item {
    border-radius: 1px !important;

    &-actions {
      i {
        font-size: 18px !important;
      }
    }
  }
}

// 省市区三联
.area-select {
  width: 100% !important;
  height: 26px !important;
  border-radius: 1px !important;

  .area-selected-trigger {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.tableWrap {
  min-height: calc(100vh - 360px)
}

// 高级查询 模块 ---------------- start
.tool-common-wrap {
  height: 32px;
  margin-bottom: 10px;
  overflow: hidden;

  .search-wrap {
    float: left;
    overflow: hidden;

    // .el-select {
    //   float: left;
    //   width: 109px;
    //   margin-right: 20px;
    //   .el-input__inner {
    //     border: 1px solid $active-color;
    //     border-radius: 2px;
    //     color: $active-color;
    //   }
    //   ::-webkit-input-placeholder {
    //     color: $active-color;
    //   }
    //   :-moz-placeholder {
    //     color: $active-color;
    //   }
    //   ::-moz-placeholder {
    //     color: $active-color;
    //   }
    //   :-ms-input-placeholder {
    //     color: $active-color;
    //   }
    //   .el-input__suffix {
    //     i {
    //       color: $active-color;
    //     }
    //   }
    // }
    .el-input-group.el-input {
      float: left;
      width: 210px;
      border-radius: 2px;

      .el-input__inner {
        color: #909399 !important;
        border: 1px solid #f5f7fa !important;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .el-input-group__append {
        width: 47px;
        padding: 0;
        color: #909399 !important;
        text-align: center;
        border: none;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        background-color: #f5f7fa !important;
        cursor: pointer;

        span {
          display: block;
        }

        .el-button {
          padding: 0;
        }

        i {
          font-size: 22px;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  .btn-wrap {
    float: right;
  }

  .btn-wrap {
    float: right;
  }

  [class^="el-icon-"] {
    position: relative;
    //left: 3px;
  }
}

.power-search {
  margin-top: 10px;
  padding: 15px;
  border: 1px solid $dc1;
  border-radius: 1px;

  .el-col-8 {
    height: 47px;
  }
}

// 高级查询 模块 ---------------- end

.el-date-editor {
  width: 100%;
}

.ck-editor__editable {
  min-height: 200px !important;
}

.tr {
  border: 1px solid $dc1;
  margin-top: 20px;

  .tr-title {
    padding: 8px 10px;
    background: $bc2;
    border-bottom: 1px solid $dc1;
    display: flex;
    align-items: center;

    span {
      margin-left: 50px !important;

      &:first-child {
        margin-left: 0 !important;
      }
    }
  }

  .tr-body {
    .td {
      padding: 15px 20px;

      // text-align: center;
      .el-button {

        // width: 100%;
        span {
          display: inline-block;
          width: 100%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $bc6;
}

.el-table th {
  // background-color: #F5F5F5 !important;
  // border: 1px solid #dddddd;
}

.el-image-viewer__close .el-icon-circle-close {
  color: #ffffff !important;
}

.el-checkbox,
.el-radio {
  margin-right: 20px;
}

.el-pagination {
  text-align: right;
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700;
  // margin: 5px 0;

  .el-pagination__rightwrapper {
    margin-left: 30px;
  }
}

.el-pagination .el-select .el-input {
  width: 100px;
  margin: 0 5px;
}

.el-input--mini .el-input__inner {
  height: 28px;
  line-height: 28px;
}

.el-input--mini .el-input__icon {
  line-height: 28px;
}

.el-card {
  border: none !important;
}

.el-input__inner:focus {
  border-color: $bc3  !important;
}

.is-checked .el-checkbox__input.is-disabled+span.el-checkbox__label,
.el-radio__input.is-disabled+span.el-radio__label {
  color: $tc1  !important;
}

.is-checked .el-radio__input.is-disabled.is-checked .el-radio__inner {
  border-color: $bc4  !important;

  &:after {
    background-color: $bc4  !important;
  }
}

.is-checked .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  border-color: $bc4  !important;
  background-color: $bc4  !important;

  &:after {
    border-color: $bc1  !important;
  }
}

.table-header {
  position: relative;
  background: $bc2;
  display: flex;
  align-items: center;
  margin-top: 20px;

  .SortButton {
    margin-top: 0 !important;
  }

  .all-check {
    position: absolute;
    left: 10px;
    top: 10px;
    margin-top: 1px;
  }
}

.scrollbar-dialog {
  .el-dialog__body {
    padding: 0;

    .el-scrollbar {
      height: 68vh;

      .el-scrollbar__wrap {
        overflow-x: auto;

        .el-scrollbar__view {
          overflow-x: hidden;
        }
      }

      .el-scrollbar__bar.is-horizontal {
        display: none;
      }

      .el-form {
        padding: 10px 20px;
      }
    }
  }
}

.el-form-item__label {
  font-size: 13px;
  word-break: break-all;
}

.el-card {
  border: none;
}

.tool-common-wrap {
  .operate-wrap {
    float: left;
    margin-left: 10px;
    line-height: 28px;

    .el-button {
      font-size: 16px;
      padding: 0;
    }
  }
}

.commont-cart {
  .el-card__body {
    overflow: hidden;

    .left-content {
      float: left;
      width: 360px;
      margin-right: 20px;
    }
  }
}

.right-content,
.layer-content {
  overflow: hidden;

  h3 {
    font-size: 14px;
    line-height: 32px;
    padding: 0 10px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
  }

  .formGroup {
    h4 {
      line-height: 30px;
      padding: 0 10px;
      font-size: 13px;
      position: relative;
      margin-bottom: 5px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 3px;
        height: 14px;
        background-color: $active-color;
      }
    }
  }
}

.icon-button {
  font-size: 14px;
}

.isrequired {
  color: #ea3c40;
}

.el-tooltip__popper {
  padding: 5px 10px;
}

.table-link {
  display: inline;
  color: $active-color  !important;
  line-height: 14px;
  transition: all .2s;
  font-size: 12px;
}

.header-nav {
  height: 35px !important;
  // background: #1e2124;
  // background: url('../img/header-nav.png');
}

.el-main {
  overflow: hidden;
}

// --------------------------------------------
//树的添加删除操作
.custom-tree-node .tree_operation .tree_operation_span {
  padding: 0 3px 0 3px;

  .el-icon-circle-plus-outline {
    color: #4275B7;
    font-weight: 600;
  }

  .el-icon-edit-outline {
    color: #4275B7;
    font-weight: 600;
  }

  .el-icon-delete {
    color: #f56c6c;
  }
}

.custom-tree-node .tree_operation {
  visibility: hidden;
}

.el-tree-node__content:hover {
  .custom-tree-node .tree_operation {
    visibility: visible;
  }
}

//tab切换。为卡片模式
//tab切换。为卡片模式
.el-tabs--border-card {
  box-shadow: none;
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item {
  color: #333;
}

.el-tabs--border-card .is-scrollable {
  background: #f2f2f2;
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
  font-size: 14px;
  color: #1e91d2;
  box-shadow: inset 3px 2px 4px rgba(0, 0, 0, 0.1);
  background: #f2f2f2 !important;

}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  border-bottom: 2px solid #ddd;
  border-bottom-color: #409eff !important;
  background: white !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__nav {
  // border-bottom: 1px solid #2d42af !important;
}

.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
  // border-bottom-color: #2d42af !important;
}

.el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
  color: #1e91d2;
}

.el-tabs__nav-wrap::after {
  height: 1px;
}

.el-tabs--border-card>.el-tabs__content {
  padding: 0;
}

.el-tabs__nav-scroll {
  // background: #fff;
  // background: -webkit-linear-gradient(#f1f1f1, #d2d2d2);
}

//分页器
.el-pagination.is-background .el-pager li {
  border: #ccc solid 1px;
  background: #fafafa;
  color: #999;
  font-size: 12px;
}

.el-pagination.is-background .el-pager li:hover {
  border: #ccc solid 1px;
  background: #fafafa;
  color: #999;
  font-size: 12px;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  border: #ccc solid 1px;
  background: #e9ebe9;
  color: #333;
  font-size: 12px;
}

//输入框限制数字
// .el-input__suffix {
//   top: 22px !important;

//   .el-input__suffix-inner i {
//     position: absolute;
//     right: 5px;
//     top: -22px;
//   }
// }

//对话框样式
.el-dialog {
  // border: 1px solid #4275B7;

  // border: #383f49 solid 4px;
  .el-dialog__header {
    background: #ebeef5 !important;
    // border-bottom: 1px solid #f5f5f5;
    // border-radius: 5px 5px 0 0;
    // background: -webkit-linear-gradient(#535c6d, #383f49);
    // height: 32px;
    // color: #b5b7c3;
    // line-height: 35px;
    // padding: 0;

    span {
      color: #888;
      font-size: 16px;
      padding-left: 20px;
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #909399;
      // background: #1a2132;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      position: relative;
      top: -9px;
      line-height: 22px;
      font-weight: 600;
    }
  }

  //  .el-dialog__body {
  //   padding: 30px;
  // }
  .el-dialog__body {
    padding: 30px;
    // border: #383f49 solid 4px;
    border-top: none;
    border-bottom: none;
    margin-top: -1px;
    // background: #f2f2f2;
    background: #fff;
  }

  .el-dialog__footer {
    background: #ebeef5 !important;
    padding: 8px;
    // border: #383f49 solid 4px;
    border-top: none;
    // background: #f2f2f2;
    background: #fff;
    // border-radius: 0 0 6px 6px;
  }
}

.el-popper {
  max-width: 500px !important;
  overflow: auto;
}

.left_s {
  width: 248px;
  height: 100%;
  // max-height: 500px;
  // background: #fafafa;
  // border-radius: 0 5px 0 0;
  // border: 1px solid #EBEEF5;
  // overflow: auto;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}

.text-right {
  text-align: right;
}

.pagination {
  margin-top: 10px;
}

// 列表操作图标按钮
.opicon {
  font-weight: 600;
  color: #4275b7;
  font-size: 16px;
  cursor: pointer;
}

//高级搜索
.seniorsearch {
  padding: 10px;
  border: 1px dashed #ddd;
  margin-bottom: 10px;
}

.el-table {
  border: 1px solid #e5e5e5;
  // border-radius: 6px 6px 0 0;
}

.el-table::before {
  height: 0;
}

.el-table th {
  // background: -webkit-linear-gradient(#f1f1f1, #d2d2d2) !important;
}


//表格头部圆角
.el-table__header-wrapper {
  // border-radius: 5px 5px 0 0;
  // background: -webkit-linear-gradient(#f1f1f1, #d2d2d2);
}

//表格内容居中
.el-table th>.cell {
  // text-align: center;
}

.el-table .cell {
  // text-align: center;
}

.el-table--border th,
.el-table--border td {
  border-right: none;
}

.mod-p {
  width: 96%;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
  color: #666;
  font-size: 14px;
  font-weight: 600;
  background: -webkit-linear-gradient(#f1f1f1, #d2d2d2);
  border-radius: 0 5px 0 0;
}

.el-aside {
  overflow: hidden;
  min-height: 500px;
  background: #fafafa;
  border-radius: 0 5px 0 0;
  border: 1px solid #EBEEF5;
}

.el-tree {
  background: none;
}

//树选中的文字颜色，未生效
.el-tree-node:focus>.el-tree-node__content {
  background-color: #f5f7fa;
  color: #666;
}

.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  color: #666;
  background: #dfe8f6;
}

.search_buttons_wrap {
  text-align: right;
}

// button公共样式
.common-btn-senior-search {
  width: 100px;
  height: 32px;
}

// select公共样式
.common-select .el-input__inner {
  height: 32px !important;
}

.common-select .el-input--suffix .el-input__inner {
  width: 100% !important;
}

//调整右侧icon的布局中
.common-select .el-input__icon {
  line-height: 28px !important;
}

// 已售
.warning-sold {
  background-color: #F5545A !important;
  color: #fff;
}

.warning-selling {
  background-color: #45b6af !important;
  color: #fff;
}

.warning-for_sale {
  background-color: #C6C6C6 !important;
  color: #fff;
}

.warning-forsale {
  background-color: #D3DCE6 !important;
  // color: #fff;
}